<template>
  <div class="about-us-page-view">
    <div class="about">
      <h1>{{$t('aboutUs.Abuout Us')}}</h1>
      <p style="margin: 15px 0;"><img src="@/assets/img/about.jpg" height="278px"></p>
      <b style="font-size: 16px;">{{$t('aboutUs.Shelf Label Solutions')}}</b><br/><br/>
      <p>{{$t('aboutUs.paragraph1')}}</p>
      <p>{{$t('aboutUs.paragraph2')}}</p>
      <p>{{$t('aboutUs.paragraph3')}}</p>
      <p>{{$t('aboutUs.paragraph4')}}</p>
      <p>{{$t('aboutUs.paragraph5')}}</p>
      <p>{{$t('aboutUs.paragraph6')}}</p>
      <p>{{$t('aboutUs.paragraph7')}}</p>
      <p><a href="https://www.blozi.com/" target="_blank">www.blozi.com</a></p>
      <!-- <p>
        Founded in 2011, BOWTZ Technology has already become one of the world's top Electronic Shelf Labels solution 
        providers. Thanks to its continuous investment in R&D and consistent product improvement as well as software iteration,
        BOWTZ's 4th generation Electronic Shelf Label systems ( ESL) are serving more than 10,000 stores world-wide.<br/><br/>

        Electronic Shelf Labels are now TRENDING and are considered important IOT EDGE SOLUTIONS  that improve retail store
        efficiency significantly. It also connects retail storefronts to online shopping, helping retail businesses to better 
        serve consumers, promote sales, develop memberships, optimize in-store layout settings while achieving multi-channel 
        marketing. Customers will have a better in-store experience while learning all the product information simply by 
        scanning the QR code on the dynamic shelf label or through NFC.  With a single scan of the barcode, customers can 
        enjoy the same services at the physical stores, as online; including exchanges and returns.  <br/><br/>

        In the past, to do any promotion, retail stores needed to spend a lot of manpower and OVER time hours. With 
        Electronic Shelf Labels, changing-prices is made so easy. Store managers can simply change ALL price tags with 
        "one-touch" USING the APP on their smartphone -- which is connected to a backend IT system through a secured network. <br/><br/>

        For grocery stores, a high waste rate on fresh items was always an issue. NOW with ESL labels, you can set 
        up instant promotions any time you need.  Our labels have visible flashing light settings. When the RED light 
        flashes, it signifies insufficient inventory, when the BLUE light blinks, it means the expiration date is soon 
        approaching, so you may start a promotion right away.<br/><br/>

        BOWTZ ESL labels offer different deployment solutions to support all sizes and scales of retail stores and 
        chains. We were the first in the industry to provide the secured SaaS Cloud services to help retail stores 
        utilize the most up-to-date technology while managing cost in the most efficient way.  Our solutions also work 
        efficiently and securely with your on-premises IT infrastructure.<br/><br/>

        A large supermarket has an average of 25,000 paper price tags, and about 4,000 of them are required to be 
        changed every week. Electronic Shelf Labels also help reduce the loss caused by human error. In addition you will 
        see tremendous savings on your labor COSTS.  Now your staff can focus on more important things like customer 
        satisfaction.  PLUS savings on  paper consumption are recognized and appreciated as more environmentally friendly.  <br/><br/>

        BOWTZ Technology, with our most secured ESL Solutions -  software, network systems, labels and accessories, 
        mobile App and our premium services. We make your Smart Store happen!  Partner with us. Together, we will 
        advance the way your retail store works!<br/><br/>

        <a href="https://www.blozi.com/" target="_blank">www.blozi.com</a>
      </p> -->
    </div>
	</div>
</template>
<script>
  export default {
    name:'',
    components: {
    },
    props: {},
    data() {
      return {
        list:[]
      }
    },
    methods: {
    }
  }
</script>
<style lang="scss" scoped>
  .about-us-page-view{
    .about{
      background: #fff;
      border-radius: 6px;
      box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.05);
      padding: 15px;
      // width: 60%;
      width: 100%;
    }
		.about h1{
      border-bottom: 1px #eee solid;
      padding-bottom: 10px;
      font-size: 14px;
      font-weight: normal;
    }
  } 
</style>